import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_show_errors",
  ref: "showErrorsModalRef",
  tabindex: "-1",
  "aria-hidden": "true",
  style: `z-index: 9999 !important`
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content p-0 m-0" }
const _hoisted_4 = { class: "modal-body p-0 m-0" }
const _hoisted_5 = { class: "alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10" }
const _hoisted_6 = { class: "svg-icon svg-icon-5tx svg-icon-danger mb-5" }
const _hoisted_7 = { class: "text-center text-dark" }
const _hoisted_8 = { class: "fw-bolder mb-5" }
const _hoisted_9 = {
  key: 0,
  class: "separator separator-dashed border-danger opacity-25 mb-5"
}
const _hoisted_10 = { class: "mb-9" }
const _hoisted_11 = {
  key: 0,
  class: "mt-5"
}
const _hoisted_12 = {
  key: 1,
  class: "separator separator-dashed border-danger opacity-25 mb-5"
}
const _hoisted_13 = {
  key: 2,
  class: "mt-5 list-group list-group-flush"
}
const _hoisted_14 = { class: "list-group-item fs-7" }
const _hoisted_15 = { class: "badge badge-light-danger badge-lg" }
const _hoisted_16 = { class: "d-flex flex-center flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen044.svg" })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("h1", _hoisted_8, _toDisplayString(_ctx.errors.message
                    ? _ctx.errors.message
                    : "Unfortunately there was an error in receiving the information"), 1),
              (_ctx.errors && _ctx.errors.data)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.errors && _ctx.errors.data)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors.data, (v, k) => {
                        return (_openBlock(), _createElementBlock("li", {
                          class: "mb-2 text-danger fs-7",
                          key: k
                        }, _toDisplayString(v[0]), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.errors && _ctx.errors.data)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12))
                  : _createCommentVNode("", true),
                (_ctx.errors && _ctx.errors.code)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                      _createElementVNode("li", _hoisted_14, [
                        _createTextVNode(_toDisplayString(_ctx.translate("Error code")) + ": ", 1),
                        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.errors.code), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_16, [
                (_ctx.errors.code !== 401)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args))),
                      class: "btn btn-danger m-2",
                      "data-bs-dismiss": "modal"
                    }, _toDisplayString(_ctx.translate("Ok")), 1))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      type: "button",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.signOut())),
                      class: "btn btn-danger m-2",
                      "data-bs-dismiss": "modal"
                    }, _toDisplayString(_ctx.translate("I will log in again")), 1))
              ])
            ])
          ])
        ])
      ])
    ])
  ], 512))
}