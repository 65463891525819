
import { defineComponent, watch } from "vue";
import { ref, onMounted, onUnmounted } from "vue";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "kt-offline-or-online",
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const isConnected = ref(window.navigator.onLine);

    const handleOnline = () => {
      isConnected.value = true;
    };

    const handleOffline = () => {
      isConnected.value = false;
    };

    onMounted(() => {
      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);
    });

    onUnmounted(() => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    });

    watch(isConnected, (last, curren) => {
      if (isConnected.value === false) {
        ElMessage({
          duration: 10000,
          showClose: true,
          center: true,
          message: translate(
            "The internet connection was interrupted Please check your internet connection and then refresh the page for the system to work properly"
          ),
          type: "error",
        });
      }

      if (last && !curren) {
        ElMessage({
          duration: 10000,
          center: true,
          showClose: true,
          message: translate("Internet connection established"),
          type: "success",
        });
      }
    });

    return {
      isConnected,
    };
  },
});
