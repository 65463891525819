
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import TutorialModal from "@/components/TutorialModal.vue";
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTLoader from "@/components/Loader.vue";
import AutoLoginBar from "@/components/AutoLoginBar.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components/index";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import { removeModalBackdrop } from "@/core/helpers/dom";
import JwtService from "@/core/services/JwtService";
import { ElMessage } from "element-plus";
import Echo from "laravel-echo";
// import BotsCheckNoticeModal from "@/components/BotsCheckNoticeModal.vue";
import ErrorHandling from "@/components/ErrorHandling.vue";
import OfflineOrOnline from "@/components/OfflineOrOnline.vue";
import ShowNoticeComponent from "@/components/ShowNoticeComponent.vue";
import CheckTabLimitForPusher from "@/components/CheckTabLimitForPusher.vue";
import ShowFreeSubscriptionTopBar from "@/components/ShowFreeSubscriptionTopBar.vue";
import {
  asideEnabled,
  contentWidthFluid,
  loaderEnabled,
  loaderLogo,
  subheaderDisplay,
  themeDarkLogo,
  themeLightLogo,
  toolbarDisplay,
} from "@/core/helpers/config";
import { platforms } from "@/store/pinia/Platforms";
import { AllBots } from "@/store/pinia/AllBots";
import { allPackages } from "@/store/pinia/AllPackages";
import userInfo from "@/core/services/UserInfo";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
declare global {
  interface Window {
    Echo: any;
    Pusher: any;
  }
}

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTToolbar,
    KTScrollTop,
    AutoLoginBar,
    KTLoader,
    TutorialModal,
    // BotsCheckNoticeModal,
    ErrorHandling,
    OfflineOrOnline,
    ShowNoticeComponent,
    CheckTabLimitForPusher,
    ShowFreeSubscriptionTopBar,
  },
  data: function () {
    return {
      minutes: 15,
    };
  },
  created() {
    const { t, te } = useI18n();
    const translateText = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    // console.log(window.location.href);
    // console.log(window.location.search.substr(1));

    // get params & create object
    var search = location.search.substring(1);

    function GFG_Fun() {
      let obj = '{"' + search.replace(/&/g, '", "').replace(/=/g, '":"') + '"}';
      console.log(obj);
      if (search) {
        sessionStorage.setItem("utm", obj);
      }
    }
    GFG_Fun();

    let checkPusherTimeOut = false;
    if (!JwtService.getToken()) {
      this.$router.push({ name: "sign-in" });
      return;
    } else {
      const route = useRoute();

      if (route.path === "/") {
        this.$router.push({ name: "dashboard" });
      }

      const MAX_CONNECTION_RETRIES = 20;
      let connectionRetries = 0;
      // const sendConnectionFailureToBackend = () => {
      //   ApiService.post("socket/set-error", {})
      //     .then(() => {
      //       //
      //     })
      //     .catch(() => {
      //       return;
      //     });
      // };

      // const checkAndDisconnectPusher = () => {
      //   checkPusherTimeOut = true;
      //   window.Echo.disconnect();
      //   Swal.fire({
      //     text: translateText("Are you online ?"),
      //     icon: "info",
      //     buttonsStyling: false,
      //     confirmButtonText: translateText("yes"),
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //     },
      //   }).then(() => {
      //     checkPusherTimeOut = false;
      //     this.minutes = 15;
      //     connectToSocket();
      //   });
      // };

      // const connectToSocket = () => {
      //   let pusherKey = window.localStorage.getItem("app_key");
      //   let socketInfo = JSON.parse(
      //     String(window.localStorage.getItem("socket"))
      //   );
      //   let user_info = JSON.parse(
      //     String(window.localStorage.getItem("user_info"))
      //   );
      //   window.Pusher = require("pusher-js");
      //   let socketOptions = {
      //     broadcaster: "pusher",
      //     key: pusherKey,
      //     encrypted: true,
      //     forceTLS: true,
      //     wsHost: socketInfo.host,
      //     wpPort: 443,
      //     enabledTransports: ["ws", "wss"],
      //     authorizer: (channel, options) => {
      //       return {
      //         authorize: (socketId, callback) => {
      //           fetch(process.env.VUE_APP_ECHO_AUTH_ENDPOINT, {
      //             method: "POST",
      //             headers: {
      //               "Content-Type": "application/json",
      //               Authorization: "Bearer " + JwtService.getToken(),
      //             },
      //             body: JSON.stringify({
      //               socket_id: socketId,
      //               channel_name: channel.name,
      //             }),
      //           })
      //             .then((response) => response.json())
      //             .then((data) => {
      //               callback(false, data);
      //             })
      //             .catch(() => {
      //               if (checkPusherTimeOut === false) {
      //                 connectToSocket();
      //               }
      //             });
      //         },
      //       };
      //     },
      //   };

      //   window.Echo = new Echo(socketOptions);
      //   window.Echo.connector.pusher.connection.bind("disconnected", () => {
      //     connectionRetries++;
      //     if (connectionRetries >= MAX_CONNECTION_RETRIES) {
      //       // sendConnectionFailureToBackend();
      //       setTimeout(() => {
      //         connectToSocket();
      //       }, 300);
      //       // return;
      //     }
      //     if (checkPusherTimeOut === false) {
      //       // connectToSocket();
      //     }
      //   });

      //   window.Echo.connector.pusher.connection.bind("error", function (error) {
      //     console.error("pusher connection error", error);
      //     // ElMessage({
      //     //   duration: 10000,
      //     //   showClose: true,
      //     //   center: true,
      //     //   message: translateText("Your connection to the socket was terminated"),
      //     //   type: "error",
      //     // });
      //   });

      //   if (
      //     user_info.type === "super_admin" ||
      //     user_info.type === "admin" ||
      //     user_info.type === "operator"
      //   ) {
      //     window.Echo.private("admins");
      //   }
      //   // else {
      //   window.Echo.private("user." + JwtService.getUserId());
      //   // }
      // };

      // setInterval(() => {
      //   if (this.minutes > 0) this.minutes -= 1;
      //   else checkAndDisconnectPusher();
      // }, 60 * 1000);

      // if (checkPusherTimeOut === false) {
      //   connectToSocket();
      // }
    }
  },
  watch: {
    minutes: function (newMinutes) {
      if (this.minutes < 10) {
        document.addEventListener("click", () => {
          if (newMinutes < 10) {
            this.minutes++;
          }
        });
      } else if (this.minutes > 15) {
        this.minutes = 15;
      }
    },
  },

  setup() {
    if (
      window.localStorage.getItem("userLoginOrRegister") === "login" ||
      window.localStorage.getItem("userLoginOrRegister") === "sign_up"
    ) {
      let userLoginOrRegister = window.localStorage.getItem(
        "userLoginOrRegister"
      );
      const dataLayerScript = document.createElement("script");
      // Add the dataLayer script to head for successful login
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': '${userLoginOrRegister}',
          'method': 'pass',
        });
      `;
      document.head.appendChild(dataLayerScript);
      // window.localStorage.removeItem('userLoginOrRegister');
      window.localStorage.removeItem("userLoginOrRegister");
    }

    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      //check if current user is authenticated
      // if (!store.getters.isUserAuthenticated) {
      //   router.push({ name: "sign-in" });
      // }
      nextTick(() => {
        reinitializeComponents();
      });
      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);

      window.addEventListener("popstate", (event) => {
        window.location.reload();
      });
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        // check if current user is authenticated
        // if (!store.getters.isUserAuthenticated) {
        //   router.push({ name: "sign-in" });
        // }

        removeModalBackdrop();
        nextTick(() => {
          reinitializeComponents();
        });
      }
    );

    onMounted(() => {
      if (
        localStorage.getItem("lang") === "en" ||
        localStorage.getItem("lang") === "tr"
      ) {
        // document.body.classList.add('bg-light')
        document.body.setAttribute("dir", "ltr");
        document.body.setAttribute("direction", "ltr");
        document.body.classList.add("other-font");
      } else {
        document.body.setAttribute("dir", "rtl");
        document.body.setAttribute("direction", "rtl");
        document.body.classList.remove("other-font");
      }
    });

    const platformsHolder = platforms();
    const { fetchPlatforms } = platformsHolder;

    const AllBotsHolder = AllBots();
    const { fetchBotList } = AllBotsHolder;

    const allPackageHolder = allPackages();
    const { fetchPackageList } = allPackageHolder;

    // pulling
    const pulling_data = ref();
    const delete_ids = ref();
    const pulling = () => {
      if (
        route.name !== "connect-to-platform" &&
        route.name !== "UsersListTable"
      ) {
        console.log("global pulling");

         ApiService.query("/whats-up", { params:{ delete_ids:delete_ids.value} })
          .then((data) => {
            if (JSON.parse(pulling_data.value).polling_mode === true) {
              console.log(data.data.data);
              let res = data.data.data.operations;
              delete_ids.value = [];

              for (let i = 0; i < res.length; i++) {
                // delete_ids.value.push(res[i].id);

                if (res[i].type === "reload_bots") {
                delete_ids.value.push(res[i].id);
                  fetchBotList();
                }
                if (res[i].type === "refresh_tickets") {
                delete_ids.value.push(res[i].id);
                  store.dispatch(Actions.VERIFY_AUTH);
                }
                if (res[i].type === "refresh_browser") {
                delete_ids.value.push(res[i].id);
                  window.location.reload();
                }
                if (res[i].type === "bot_deactivated") {
                delete_ids.value.push(res[i].id);
                  fetchBotList();
                }
              }
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    };

    onMounted(async () => {
      document.title =
        translate("Inboxino") +
        " | " +
        translate("Assistant to internal and external messengers");

      if (route.name !== "sign-in") {
        await fetchPlatforms();
        await fetchBotList();
        await fetchPackageList();
      }

      // pulling
      pulling_data.value = localStorage.getItem("user_info");

      if (pulling_data.value && JSON.parse(pulling_data.value).send_polling_requests === true) {
        setInterval(() => {
          pulling();
        }, 20000);
      }

      if (pulling_data.value && JSON.parse(pulling_data.value).polling_mode === false) {
        setTimeout(() => {
          if (JwtService.getUserId()) {
            window.Echo.private("user." + JwtService.getUserId())
              .listen(".reload_bots", () => {
                fetchBotList();
              })
              .listen(".refresh_tickets", (e) => {
                store.dispatch(Actions.VERIFY_AUTH);
              })
              .listen(".refresh_browser", (e) => {
                window.location.reload();
              });

            let user_info = JSON.parse(
              String(window.localStorage.getItem("user_info"))
            );
            if (
              user_info.type === "super_admin" ||
              user_info.type === "admin" ||
              user_info.type === "operator"
            ) {
              window.Echo.private("admins").listen(".refresh_browser", (e) => {
                window.location.reload();
              });
            }
          }
        }, 10000);
      }
    });

    const userData = userInfo.getUser();

    const disconnectFromSocket = () => {
      window.Echo.disconnect();
    };

    return {
      disconnectFromSocket,

      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      translate,
      userData,
    };
  },
});
