import UserInfo from "@/core/services/UserInfo";
const currentUser = UserInfo.getUser();
const DocMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-speedometer2",
        per: "",
      },
      // {
      //   heading: "Buy a subscription",
      //   route: "/buy",
      //   svgIcon: "/media/icons/duotune/ecommerce/ecm005.svg",
      //   fontIcon: "bi-bag",
      // },
      {
        heading: "My subscriptions",
        route: "/bot-connections",
        svgIcon: "/media/icons/duotune/art/art002.svg",
        fontIcon: "bi-gem",
        per: "",
      },
    ],
  },
  {
    heading: "services",
    route: "/services",
    pages: [
      {
        heading: "bulk-message",
        route: "/services/bulk-message",
        svgIcon: "/media/icons/duotune/communication/com012.svg",
        fontIcon: "bi-chat",
        per: "",
      },
      {
        heading: "Autoresponder",
        route: "/services/Secretary",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-emoji-smile",
        per: "",
      },
      {
        heading: "api",
        route: "/services/api",
        svgIcon: "/media/icons/duotune/coding/code003.svg",
        fontIcon: "bi-key",
        per: "",
      },
      // {
      //   heading: "onlineQuestionnaire",
      //   route: "/services/questionnaire",
      //   svgIcon: "/media/icons/duotune/coding/code003.svg",
      //   fontIcon: "bi-ui-checks",
      // },
      // {
      //   heading: "Active subscriptions",
      //   route: "/services/multi-user",
      //   svgIcon: "/media/icons/duotune/communication/com007.svg",
      //   fontIcon: "bi-people",
      // },
      // {
      //   heading: "reservation",
      //   route: "/services/reservation",
      //   svgIcon: "/media/icons/duotune/communication/com007.svg",
      //   fontIcon: "bi-calendar-check",
      // },
    ],
  },
  ["admin", "super_admin"].includes(currentUser?.type) && {
    heading: "management",
    route: "/management",
    pages: [
      {
        sectionTitle: "management",
        route: "/management",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-layers",
        sub: [
          
          {
            heading: "داشبورد مدیریت",
            route: "/management",
            per: "",
          },


          // users
          ["user_list", "create_user" , "bot_list", "activation_codes", "bot_expiry_list", "bot_expiry_list" , "admin_list"].some(r=> currentUser?.permissions.includes(r)) &&  {
            sectionTitle: "users",
            route: "/management",
            sub: [
              {
                heading: "users_list",
                route: "/management/users",
                per: "user_list",
              },
              {
                heading: "افزودن کاربر",
                route: "/management/add-user",
                per: "create_user",
              },
              {
                heading: "subscription management",
                route: "/management/bots",
                per: "bot_list",
              },
              {
                heading: "کد های تایید کاربران",
                route: "/management/otp-codes",
                per: "activation_codes",
              },
              {
                heading: "expiring-bots",
                route: "/management/expiring-bots",
                per: "bot_expiry_list",
              },
              {
                heading: "لیست مدیران",
                route: "/management/adminsList",
                per: "admin_list",
              },
            ],
          },

          // transactions
          ["transaction_list", "create_factor" , "discount_list", "agents_list", "package_list", "cooperation_requests_list"].some(r=> currentUser?.permissions.includes(r)) &&  {
            sectionTitle: "تراکنش ها و مالی",
            route: "/management",
            sub: [
              {
                heading: "transactions",
                route: "/management/transactions",
                per: "transaction_list",
              },
              {
                heading: "ایجاد لینک خرید",
                route: "/management/buy-link-generate",
                per: "create_factor",
              },
              {
                heading: "discounts",
                route: "/management/discounts",
                per: "discount_list",
              },
              {
                heading: "packages",
                route: "/management/packages",
                per: "package_list",
              },
              {
                heading: "agency-requests",
                route: "/management/agency-request",
                per: "agents_list",
              },

              {
                heading: "cooperationInSales",
                route: "/management/cooperationInSales",
                per: "cooperation_requests_list",
              },
            ],
          },

          // services
          ["message_list", "secretary_list" , "notifications_log"].some(r=> currentUser?.permissions.includes(r)) &&  {
            sectionTitle: "خدمات",
            route: "/management",
            sub: [
              {
                heading: "bulk-message",
                route: "/management/bulk-message",
                per: "message_list",
              },
              {
                heading: "Autoresponder",
                route: "/management/Secretary",
                per: "secretary_list",
              },
              {
                heading: "api",
                route: "/management/api",
                per: "notifications_log",
              },
            ],
          },

          // other
          ["notice_list", "server_list"].some(r=> currentUser?.permissions.includes(r)) &&   {
            sectionTitle: "others",
            route: "/management/others",
            sub: [

              {
                heading: "اطلاعیه ها",
                route: "/management/notice",
                per: "notice_list",
              },

              {
                heading: "servers",
                route: "/management/servers",
                per: "server_list",
              },

            ],
          },

        ],
      },
    ],
  },

  {
    heading: "others",
    route: "/others",
    pages: [
      {
        sectionTitle: "others",
        route: "/management",
        svgIcon: "/media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-layers",
        sub: [
          {
            heading: "phone book",
            route: "/phone-book",
            svgIcon: "/media/icons/duotune/communication/art005.svg",
            fontIcon: "bi-file-person",
            per: "",
          },
          {
            heading: "tickets",
            route: "/support",
            svgIcon: "/media/icons/duotune/communication/com011.svg",
            fontIcon: "bi-headset",
            per: "ticket_list",
          },
          {
            heading: "transactions",
            route: "/transactions",
            svgIcon: "/media/icons/duotune/finance/fin003.svg",
            fontIcon: "bi-receipt",
            per: "",
          },
          {
            heading: "cooperationInSales",
            route: "/cooperationInSales",
            svgIcon: "/media/icons/duotune/communication/com011.svg",
            fontIcon: "bi-bag-check",
            per: "",
          },
          {
            heading: "web-hook",
            route: "/webhook",
            svgIcon: "/media/icons/duotune/communication/com011.svg",
            fontIcon: "bi-bezier2",
            per: "",
          },



          // {
          //   heading: "پکیج ها و تعرفه ها",
          //   route: "/packages_and_tariffs",
          //   svgIcon: "/media/icons/duotune/art/art002.svg",
          //   fontIcon: "bi-cash-stack",
          // },


          // {
          //   heading: "Introducing the services",
          //   route: "/support/Introducing-the-services",
          //   svgIcon: "/media/icons/duotune/general/gen046.svg",
          //   fontIcon: "bi-award",
          //   per:"",
          // },
          // {
          //   heading: "درخواست نمایندگی",
          //   route: "/agency-request",
          //   svgIcon: "/media/icons/duotune/finance/fin003.svg",
          //   fontIcon: "bi-person-check",
          // },
          // {
          //   heading: "پیام‌های دریافتی منشی",
          //   route: "/services/Secretary/secretary-received-messages",
          //   svgIcon: "/media/icons/duotune/art/art002.svg",
          //   fontIcon: "bi-chat-left",
          // },
          // {
          //   heading: "گزارشات",
          //   route: "/report",
          //   svgIcon: "/media/icons/duotune/art/art002.svg",
          //   fontIcon: "bi-bar-chart",
          // },
          // {
          //   heading: "اعلان ها",
          //   route: "/notification",
          //   svgIcon: "/media/icons/duotune/art/art002.svg",
          //   fontIcon: "bi-bell",
          // },
          {
            heading: "Help and training",
            route: "/help",
            svgIcon: "/media/icons/duotune/general/gen046.svg",
            fontIcon: "bi-patch-question",
            per: "",
          },
          {
            heading: "Terms and Conditions",
            route: "/privacy",
            svgIcon: "/media/icons/duotune/general/gen046.svg",
            fontIcon: "bi-exclamation-triangle",
            per: "",
          },
          // {
          //   heading: "settings",
          //   route: "/settings/overview",
          //   svgIcon: "/media/icons/duotune/coding/code001.svg",
          //   fontIcon: "bi-gear",
          // },
        ],
      },
    ],
  },
  currentUser?.crawler_enabled ? {
    heading: "bank No",
    route: "/bank",
    pages: [
      {
        heading: "اشتراک ها",
        route: "/bank/subscriptionList",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-download",
        per: "",
      },
      {
        heading: "درخواست های استخراج",
        route: "/bank/requestList",
        svgIcon: "/media/icons/duotune/communication/com012.svg",
        fontIcon: "bi-bank",
        per: "",
      },

    ],
  } : {},
];

export default DocMenuConfig;
