import { defineStore } from "pinia";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import axios from "axios";

export const Conversations = defineStore("conversationsinfo", () => {
  const store = useStore();

  // state
  const conversationsList = ref();
  const conversationBody = ref();

  const filterConversation = ref({
    status: false,
    search: "",
  });

  const targetConversationId = ref();
  const lastMessageId = ref();

  const tryCounter = ref(0);
  const loadingConversations = ref(false);
  const loadingConversationBody = ref(false);

  // getters
  const activeConversation = computed(() => {
    if (!conversationsList.value) return;

    const v = conversationsList.value.find(
      (item) => item.conversation_id === targetConversationId.value
    );
    return v ? JSON.parse(JSON.stringify(v)) : null;
  });

  const unReadConversation = computed(() => {
    if (!conversationsList.value) return 0;
    return conversationsList.value.filter((item) => item.status !== "answered")
      .length;
  });

  // actions

  const setFilterConversation = async (search, status) => {
    filterConversation.value.search = search;
    filterConversation.value.status = status;
    await fetchConversations();
  };

  let cancelPreviousRequest = null as any;

  const cancelRequest = () => {
    if (cancelPreviousRequest) {
      cancelPreviousRequest();
    }
  };

  const closeConversation = async (id) => {
    try {
      await ApiService.post("/admin/conversation/set-answered", {
        conversation_id: id,
      });
      fetchConversations();
    } catch (error) {
      if (error.response && error.response.data) {
        store.commit(Mutations.SET_ERROR, error.response.data);
      }
    }
  };

  const fetchConversations = async () => {
    const endPoint = `/admin/conversation?with_country=true${
      filterConversation.value.status === true
        ? `&pending=` + filterConversation.value.status
        : ""
    }&search=${filterConversation.value.search}`;

    try {
      loadingConversations.value = true;
      cancelRequest(); // لغو درخواست قبلی
      const response = await ApiService.post(
        endPoint,
        {},
        {
          cancelToken: new axios.CancelToken(function executor(c) {
            cancelPreviousRequest = c;
          }),
        }
      );
      conversationsList.value = response.data.data.conversations;
      tryCounter.value = 0;
    } catch (error) {
      if (tryCounter.value < 3) {
        tryCounter.value++;
        await fetchConversations();
      }
      if (error.response && error.response.data) {
        store.commit(Mutations.SET_ERROR, error.response.data);
      }
    } finally {
      loadingConversations.value = false;
    }
  };

  const fetchConversationBody = (id) => {
    loadingConversationBody.value = true;
    targetConversationId.value = id;
    ApiService.post("/admin/conversation/messages", {
      conversation_id: targetConversationId.value,
      message_id: lastMessageId.value,
    })
      .then(({ data }) => {
        if (conversationBody.value && lastMessageId.value) {
          if (data.data.messages.length) {
            data.data.messages.map((item) => {
              conversationBody.value.push(item);
            });
          }
        } else {
          conversationBody.value = data.data.messages;
        }
        lastMessageId.value = data.data.first_message;
      })
      .catch(({ response }) => {
        if (response && response.data)
          store.commit(Mutations.SET_ERROR, response.data);
      })
      .finally(() => {
        loadingConversationBody.value = false;
      });
  };

  const findConversation = (converse_id) => {
    return conversationsList.value
      ? conversationsList.value.find(
          (item) => item.conversation_id === converse_id
        )
      : undefined;
  };

  const setLastMessageIdNull = () => {
    lastMessageId.value = null;
  };

  const saveNewMessage = async (formData) => {
    try {
      ApiService.setHeader();
      const { data } = await ApiService.post(
        "/admin/conversation/response-to-chat",
        formData
      );
      changeConversationStatus("answered");
      conversationBody.value.unshift(data.data.message);
      return true;
    } catch (error) {
      if (error.response && error.response.data) {
        store.commit(Mutations.SET_ERROR, error.response.data);
      }
    }
  };

  const changeConversationStatus = (status) => {
    const exits_con = findConversation(targetConversationId.value);
    if (exits_con !== undefined) exits_con.status = status; // change conversation status
  };

  return {
    filterConversation,
    conversationsList,
    loadingConversations,
    conversationBody,
    loadingConversationBody,
    activeConversation,
    targetConversationId,
    lastMessageId,
    unReadConversation,
    setLastMessageIdNull,
    fetchConversations,
    fetchConversationBody,
    findConversation,
    saveNewMessage,
    setFilterConversation,
    closeConversation,
  };
});
