import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d8a5fba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aside-menu flex-column-fluid" }
const _hoisted_2 = { class: "ki-duotone ki-black-left-line fs-3 rotate-180" }
const _hoisted_3 = { class: "svg-icon svg-icon-3 me-0 path1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTMenu = _resolveComponent("KTMenu")!

  return (_openBlock(), _createElementBlock("div", {
    id: "kt_aside",
    class: _normalizeClass(["aside card", [_ctx.asideMinimize === true ? 'minimize' : '']]),
    style: _normalizeStyle(_ctx.width < 992 ? 'display:none!important' : ''),
    "data-kt-drawer": "true",
    "data-kt-drawer-name": "aside",
    "data-kt-drawer-activate": "{default: true, lg: false}",
    "data-kt-drawer-overlay": "true",
    "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
    "data-kt-drawer-direction": "end",
    "data-kt-drawer-toggle": "#kt_aside_mobile_toggle"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        id: "kt_app_sidebar_toggle",
        class: "app-sidebar-toggle btn btn-icon btn-circle btn-white btn-sm h-30px w-30px position-absolute translate-middle rotate z-index-1 border border-gray-300",
        style: {"top":"2%","left":"1%"},
        "data-kt-toggle": "true",
        "data-kt-toggle-state": "active",
        "data-kt-toggle-target": "body",
        "data-kt-toggle-name": "app-sidebar-minimize",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeAsideWidth(!_ctx.asideMinimize)))
      }, [
        _createElementVNode("i", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_inline_svg, { src: "/media/icons/duotune/arrows/arr064.svg" })
          ])
        ])
      ]),
      _createVNode(_component_KTMenu)
    ])
  ], 6))
}