
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { useI18n } from "vue-i18n";
import { globals } from "@/store/pinia/Globals";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const permission = ref();
    // const crawler_enabled = ref(false);

    onMounted(() => {
      setTimeout(() => {
        let per: any = localStorage.getItem("permissions");
        permission.value = JSON.parse(per);

        // let crawler: any = localStorage.getItem("crawler_enabled");
        // crawler_enabled.value = JSON.parse(crawler);
      }, 3000);

      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const globalsHolder = globals();
    const { asideMinimize } = storeToRefs(globalsHolder);

    const mouseTouch = ref(false);
    const MenuMouseEnter = () => {
      if (asideMinimize.value) {
        asideMinimize.value = false;
        mouseTouch.value = true;
      }
    };

    const MenuMouseLeave = () => {
      if (mouseTouch.value) {
        asideMinimize.value = true;
        mouseTouch.value = false;
      }
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      translate,
      asideMinimize,
      MenuMouseEnter,
      MenuMouseLeave,
      permission,
      // crawler_enabled,
    };
  },
});
