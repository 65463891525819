
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";

import {
  headerWidthFluid,
  headerFixed,
  headerFixedOnMobile,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    KTMenu,
  },
  setup: function () {
    const width = ref(window.innerWidth);
    const logo = computed(() => {
      if (width.value > 500) {
        return "/media/logos/logo-1.png";
      }
      return "/media/logos/logo-5.png";
    });

    onMounted(() => {
      window.addEventListener("resize", () => {
        width.value = window.innerWidth;
      });
    });

    watch(width, (currentValue) => {
      width.value = currentValue;
    });

    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value;
      } else {
        return headerFixedOnMobile.value;
      }
    });

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      isHeaderSticky,
      width,
      logo,
    };
  },
});
