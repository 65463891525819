
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { showModal, hideModal } from "@/core/helpers/dom";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "kt-error-handling",
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const showErrorsModalRef = ref<HTMLElement | null>(null);
    const store = useStore();
    const errors = computed(() => {
      if (store.getters.getErrors)
        return JSON.parse(JSON.stringify(store.getters.getErrors));
      return [];
    });

    const clear = () => {
      store.commit(Mutations.SET_ERROR, []);
      hideModal(showErrorsModalRef.value);
    };

    watch(errors, () => {
      if (errors.value !== null && errors.value.message) {
        showModal(showErrorsModalRef.value);
      }
    });

    const router = useRouter();
    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    return {
      store,
      errors,
      clear,
      showErrorsModalRef,
      translate,
      signOut,
    };
  },
});
