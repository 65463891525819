<template>
  <div class="login-bar" v-if="enabled">
    <div class="vol-md-12 py-3">
      {{ translate("You have entered another user panel") }}
      {{ translate("to exit") }}
      <span style="cursor: pointer;" @click="signOut()">
        {{ translate("clicked") }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from "vue";
import { checkAutoLogin, exitAutoLogin } from "@/core/services/AutoLogin";
import { translate } from "@/core/services/HelpersFun";
export default defineComponent({
  name: "AutoLoginBar",
  setup() {
    const enabled = ref(checkAutoLogin());

    watchEffect(() => {
      enabled.value = checkAutoLogin();
    });

    const signOut = () => {
      if (enabled.value) exitAutoLogin();
    };

    return {
      enabled,
      signOut,
      translate,
    };
  },
});
</script>

<style scoped>
.login-bar {
  width: 100%;
  display: block;
  overflow: hidden;
  text-align: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #c6cc4b;
  color: #050502;
}
</style>
