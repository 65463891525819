
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";
import BuyNew from "@/views/pages/buy/buy-new.vue";
import BuyHandler from "@/views/pages/buy/Buy/BuyHandler.vue";
import userInfo from "@/core/services/UserInfo";
export default defineComponent({
  name: "kt-free-subscription-topBar-h",
  components: {
    BuyNew,
    BuyHandler,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const userData = userInfo.getUser();
    const dialogVisible = ref(false);

    const selectedBot = ref();
    const AllBotsHolders = AllBots();
    const { botList, findFreeBots } = storeToRefs(AllBotsHolders);

    const numbers = computed(() => {
      if (!findFreeBots.value) return;
      return {
        package_max_send_limit:
          findFreeBots.value[0]?.package?.package_max_send_limit,
        messages_sent_count:
          findFreeBots.value[0]?.package?.messages_sent_count,
        expired_date: findFreeBots.value[0]?.package?.expired_date,
        status: findFreeBots.value[0]?.pay_status,
        expiry_date_assigned: findFreeBots.value[0]?.package?.expiry_date_assigned
      };
    });

    function checkExpirationDate(packageDate) {
      // تاریخ فعلی
      const currentDate = new Date();

      // تاریخ انقضای موردنظر از `expired_date.gregorian` (فرضاً این رشته را مقداردهی می‌کنیم)
      const expiredDateStr = packageDate; // تاریخ انقضا به عنوان یک رشته

      if (expiredDateStr) {
        // تبدیل تاریخ انقضا به تاریخ میلادی
        const expiredDate = new Date(expiredDateStr);

        // چک کردن اگر تاریخ انقضا بعد از تاریخ فعلی است یا خیر
        if (currentDate < expiredDate) {
          return true;
        } else if (currentDate > expiredDate) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    return {
      findFreeBots,
      translate,
      numbers,
      botList,
      selectedBot,
      dialogVisible,
      userData,
      checkExpirationDate,
    };
  },
});
