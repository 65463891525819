
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import FreeTestSubscription from "@/components/FreeTestSubscription.vue";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";
import ApiService from "@/core/services/ApiService";
import userInfo from "@/core/services/UserInfo";
import BuyNew from "@/views/pages/buy/buy-new.vue";
import BuyHandler from "@/views/pages/buy/Buy/BuyHandler.vue";
export default defineComponent({
  name: "KToolbar",
  components: {
    FreeTestSubscription,
    BuyNew,
    BuyHandler,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    const route = useRoute();
    const name = computed(() => {
      return route.name;
    });
    const loading = ref(true);

    const dialogVisible = ref(false);
    const select_bot_dialog = ref(false);
    const userData = userInfo.getUser();
    const botList = ref();
    const selectedBot = ref();

    // const AllBotsHolder = AllBots();
    // const { botList, findFreeBots } = storeToRefs(AllBotsHolder);
    // const { fetchBotList } = AllBotsHolder;

    const getBotList = async (ex_type) => {
      await ApiService.query("bot/all", {
        params: {
          with_platforms: true,
          with_user: true,
          expiration_type: ex_type,
          type: 'light',
        },
      })
        .then(({ data }) => {
          // botList.value = data.data.bots;
          botList.value = data.data.bots.filter(
            (item) => item?.package?.is_chargeable === "yes"
          );
        })
        .catch(({ response }) => {
          return;
        });
    };

    const btn_disabled = ref(false);
    const check_bots = async () => {
      loading.value = true;
      btn_disabled.value = true;
      await getBotList("");
      btn_disabled.value = false;
      if (
        botList.value[0]?.name === "اشتراک تست رایگان" &&
        botList.value.length === 1
      ) {
        selectedBot.value = botList.value;
        dialogVisible.value = true;
        loading.value = false;
      } else if (botList.value.length <= 0) {
        selectedBot.value = null;
        dialogVisible.value = true;
        loading.value = false;
      } else {
        select_bot_dialog.value = true;
        // await getBotList("expired");
        loading.value = false;
      }
    };

    const select_bot = (val) => {
      selectedBot.value = JSON.parse(val.target.value);
    };
    const recharge_bot = () => {
      select_bot_dialog.value = false;
      dialogVisible.value = true;
    };

    // watch(select_bot_dialog, async () => {
    //   if (select_bot_dialog.value === false) {
    //     // selectedBot.value = {}
    //     await getBotList("active");
    //   }
    //   if (select_bot_dialog.value === true) {
    //     // selectedBot.value = {}
    //     await getBotList("expired");
    //   }
    // });

    return {
      botList,
      // findFreeBots,
      name,
      translate,
      dialogVisible,
      userData,
      selectedBot,
      check_bots,
      btn_disabled,
      select_bot_dialog,
      recharge_bot,
      select_bot,
      getBotList,
      loading,
    };
  },
});
