
import { computed, defineComponent, onMounted, ref } from "vue";
// import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import UserInfo from "@/core/services/UserInfo";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import store from "@/store";
import { supportPhoneNumber } from "@/core/services/LanguageData";
import { Conversations } from "@/store/pinia/Conversations";
import { storeToRefs } from "pinia";
export default defineComponent({
  name: "topbar",
  components: {
    // KTNotificationsMenu,
    KTUserMenu,
  },
  setup() {
    const user = computed(() => {
      return store.getters.currentUser;
    });

    const width = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener("resize", () => {
        width.value = window.innerWidth;
      });
    });

    const conversationsHolder = Conversations();
    const { unReadConversation } = storeToRefs(conversationsHolder);

    return {
      unReadConversation,
      width,
      user,
      UserInfo,
      supportPhoneNumber,
    };
  },
});
