
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import { translate } from "@/core/services/HelpersFun";
export default defineComponent({
  name: "KTFooter",
  setup() {
    return {
      footerWidthFluid,
      translate,
    };
  },
});
