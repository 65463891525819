
import { defineComponent, onMounted, ref } from "vue";
// import Alert from "@/components/Alert.vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import jwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "kt-show-notice-component",
  components: {
    // Alert,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const store = useStore();
    const notices = ref();
    const show_notices = ref();
    const closed_notic = ref();
    const urlRegex = ref(/(https?:\/\/[^\s]+)/g);

    const getNotices = () => {

      ApiService.get(`notice`)
        .then(({ data }) => {
          notices.value = data.data.notices;

          // create show notic
          let storge_closed: any = localStorage.getItem("closed_notic");

          // localStorage.setItem('closed_notic' , storge_closed ? storge_closed: JSON.stringify([]) )
          storge_closed = JSON.parse(storge_closed);

          let values: any[] = data.data.notices;

          show_notices.value = values.filter(function (item) {
            return !storge_closed.includes(item.id);
          });
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const close_notic = () => {
      closed_notic.value = [];
      for (let i = 0; i < notices.value.length; i++) {
        closed_notic.value.push(notices.value[i].id);
      }
      localStorage.setItem("closed_notic", JSON.stringify(closed_notic.value));
      show_notices.value = [];
      // closed_notic_btn.value = false
    };

    onMounted(() => {


      let storge_closed: any = localStorage.getItem("closed_notic");
      localStorage.setItem(
        "closed_notic",
        storge_closed ? storge_closed : JSON.stringify([])
      );
      if (jwtService.getToken()) {
        getNotices();
      }
    });

    return {
      notices,
      closed_notic,
      show_notices,
      getNotices,
      translate,
      close_notic,
      urlRegex,
    };
  },
});
