
import { computed, defineComponent } from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import ToolbarButtonsHandler from "@/layout/toolbar/ToolbarButtonsHandler.vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import UserInfo from "@/core/services/UserInfo";
export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {
    ToolbarButtonsHandler,
  },
  setup() {
    const route = useRoute();
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const user = computed(() => {
      return UserInfo.getUser();
    });

    const routeName = computed(() => {
      return route.name;
    });

    return {
      toolbarWidthFluid,
      translate,
      routeName,
      user,
    };
  },
});
