
import { computed, defineComponent, onMounted, ref } from "vue";
import { globals } from "@/store/pinia/Globals";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import KTMenu from "@/layout/aside/Menu.vue";
import {
  asideTheme,
  headerFixed,
  headerFixedOnMobile,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const width = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener("resize", () => {
        width.value = window.innerWidth;
      });
    });

    const { t } = useI18n();

    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value;
      } else {
        return headerFixedOnMobile.value;
      }
    });

    const globalsHolder = globals();
    const { asideMinimize } = storeToRefs(globalsHolder);
    const { changeAsideWidth } = globalsHolder;

    const mouseTouch = ref(false);
    const MenuMouseEnter = () => {
      if (asideMinimize.value) {
        asideMinimize.value = false;
        mouseTouch.value = true;
      }
    };

    const MenuMouseLeave = () => {
      if (mouseTouch.value) {
        asideMinimize.value = true;
        mouseTouch.value = false;
      }
    };

    return {
      asideTheme,
      t,
      isHeaderSticky,
      width,
      asideMinimize,
      changeAsideWidth,
      MenuMouseEnter,
      MenuMouseLeave,
    };
  },
});
