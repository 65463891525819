<template>
  <div></div>
</template>
<script>
import { onBeforeUnmount, onMounted } from "vue";

export default {
  setup() {
    const checkTabs = async () => {
      const tabsOpened = localStorage.getItem("tabsOpened") || 0;

      if (tabsOpened > 0) {
        const userResponse = await window.confirm(
          "آیا می‌خواهید اینجا کار کنید؟"
        );
        if (!userResponse) {
          window.close(); // بستن تب جدید
        }
      } else {
        localStorage.setItem("tabsOpened", tabsOpened + 1);
      }
    };

    onMounted(() => {
      checkTabs();
    });

    return {};
  },
};
</script>

<!--<script lang="ts">-->
<!--import {-->
<!--  defineComponent,-->
<!--  onMounted,-->
<!--  onBeforeUnmount,-->
<!--  ref,-->
<!--  watchEffect,-->
<!--  onUnmounted,-->
<!--} from "vue";-->
<!--import { useI18n } from "vue-i18n";-->
<!--import { useRoute } from "vue-router";-->
<!--import Swal from "sweetalert2/dist/sweetalert2.min.js";-->

<!--export default defineComponent({-->
<!--  name: "kt-check-tab-limits-pusher-h",-->
<!--  components: {},-->
<!--  emits: ["discountSocket"],-->
<!--  setup(props, { emit }) {-->
<!--    const { t, te } = useI18n();-->
<!--    const translate = (text) => {-->
<!--      if (te(text)) return t(text);-->
<!--      return text;-->
<!--    };-->

<!--    const removeTabNameKey = ref("removeTabName");-->
<!--    const tabsLimit = 2; // تعداد حداکثر تب‌های مجاز-->
<!--    const tabsOpened = ref(0);-->
<!--    const route = useRoute();-->

<!--    const localStorageValue = localStorage.getItem("openedTabs");-->
<!--    const openedTabs = localStorageValue ? JSON.parse(localStorageValue) : [];-->

<!--    const ignore = ["/", "sign-in", "register"];-->

<!--    const checkTabsLimit = () => {-->
<!--      tabsOpened.value = openedTabs.length;-->

<!--      if (tabsOpened.value >= tabsLimit) {-->
<!--        Swal.fire({-->
<!--          text: translate(-->
<!--            "شما بیش از حد تب‌ها را باز کرده‌اید.استفاده از تب جدید باعث بسته شدن تب‌های قبلی خواهد شد."-->
<!--          ),-->
<!--          icon: "warning",-->
<!--          buttonsStyling: false,-->
<!--          confirmButtonText: translate("ok"),-->
<!--          customClass: {-->
<!--            confirmButton: "btn btn-warning",-->
<!--          },-->
<!--        });-->

<!--        // set removeTabNameKey value route.fullPath-->
<!--        const removeItemIs = openedTabs[0];-->
<!--        localStorage.setItem(removeTabNameKey.value, removeItemIs);-->
<!--        // remove set removeTabNameKey.value from openedTabs-->
<!--        const updatedTabs = [...openedTabs, route.fullPath].filter(-->
<!--          (item) => item !== removeItemIs-->
<!--        );-->
<!--        localStorage.setItem("openedTabs", JSON.stringify(updatedTabs));-->
<!--      } else {-->
<!--        if (-->
<!--          !ignore.includes(route.fullPath) &&-->
<!--          ![...openedTabs].includes(route.fullPath)-->
<!--        ) {-->
<!--          let updatedTabs = [...openedTabs, route.fullPath];-->
<!--          if (tabsOpened.value >= tabsLimit)-->
<!--            updatedTabs = [...openedTabs, route.fullPath].shift();-->
<!--          localStorage.setItem("openedTabs", JSON.stringify(updatedTabs));-->
<!--        }-->
<!--      }-->
<!--    };-->

<!--    onMounted(() => {-->
<!--      checkTabsLimit();-->
<!--    });-->

<!--    const unwatch = watchEffect(() => {-->
<!--      checkTabsLimit();-->
<!--    });-->

<!--    onBeforeUnmount(() => {-->
<!--      unwatch();-->
<!--    });-->

<!--    // Function to handle changes in localStorage-->
<!--    const handleStorageChange = (event) => {-->
<!--      if (event.key === removeTabNameKey.value) {-->
<!--        if (event.newValue === route.fullPath) {-->
<!--          localStorage.removeItem(removeTabNameKey.value);-->
<!--          document.close();-->
<!--          window.close();-->
<!--          Swal.fire({-->
<!--            text: translate(-->
<!--              "این صفحه غیر فعال شده است. برای مشاهده محتویات صفحه، سایت را رفرش کنید."-->
<!--            ),-->
<!--            icon: "danger",-->
<!--            buttonsStyling: false,-->
<!--            confirmButtonText: translate("ok"),-->
<!--            customClass: {-->
<!--              confirmButton: "btn btn-danger",-->
<!--            },-->
<!--          }).then(() => {-->
<!--            emit("discountSocket", true);-->
<!--            window.location.reload();-->
<!--          });-->
<!--        }-->
<!--      }-->
<!--    };-->

<!--    // Add event listener when the component is mounted-->
<!--    onMounted(() => {-->
<!--      window.addEventListener("storage", handleStorageChange);-->
<!--    });-->

<!--    // Remove event listener when the component is unmounted to prevent memory leaks-->
<!--    onUnmounted(() => {-->
<!--      window.removeEventListener("storage", handleStorageChange);-->
<!--      // remove from storage when component close-->
<!--      const updatedTabs = [...openedTabs].filter(-->
<!--        (item) => item !== route.fullPath-->
<!--      );-->
<!--      localStorage.setItem("openedTabs", JSON.stringify(updatedTabs));-->
<!--    });-->
<!--  },-->
<!--});-->
<!--</script>-->
