
import {
  defineComponent,
  ref,
  onBeforeUnmount,
  reactive,
  onMounted,
} from "vue";
import { useRoute } from "vue-router";
import { translate } from "@/core/services/HelpersFun";

export default defineComponent({
  name: "kt_tutorial_modal",
  setup: function () {
    const route = useRoute();
    const tutorials = reactive([
      // {
      //   url: "https://inboxino.com/media/panel-help.mp4",
      //   title: "Teaching how to use the Inboxino panel",
      //   desc: "",
      //   link: "",
      // },
      // {
      //   url: "https://inboxino.com/media/api.mp4",
      //   title: "Teaching web service connection",
      //   desc: "",
      //   link: "/services/api",
      // },
      {
        url: "https://www.aparat.com/video/video/embed/videohash/y0hb7/vt/frame?titleShow=true",
        title: "Autoresponder",
        desc: "",
        link: "/services/Secretary",
      },
      // {
      //   url: "https://inboxino.com/media/buy.mp4",
      //   title: "Number connection training and subscription purchase",
      //   desc: "",
      //   link: "/buy",
      // },
      {
        url: "https://www.aparat.com/video/video/embed/videohash/F4ubW/vt/frame?titleShow=true",
        title: "Training cooperation in sales",
        desc: "",
        link: "/bot-connections",
      },
      // {
      //   url: "https://inboxino.com/media/cooperation.mp4",
      //   title: "Rules for sending messages",
      //   desc: "",
      //   link: "/cooperationInSales",
      // },
      {
        url: "https://www.aparat.com/video/video/embed/videohash/wAK56/vt/frame?titleShow=true",
        title: "Teaching bulk messaging",
        desc: "",
        link: "/services/bulk-message",
      },
    ]);
    const select_video = ref();

    const videoRefs = ref(null) as any;

    const pauseVideos = () => {
      select_video.value = null
      // if (videoRefs.value) {
      //   if (Array.isArray(videoRefs.value)) {
      //     videoRefs.value?.forEach((video) => {
      //       if (!video.paused) {
      //         video.pause();
      //       }
      //     });
      //   } else {
      //     if (!videoRefs.value?.paused) {
      //       videoRefs.value?.pause();
      //     }
      //   }
      // }
    };

    const setVideos = () => {
      for (let t = 0; t < tutorials.length; t++) {
        if (tutorials[t].link === route.path) {
          select_video.value = tutorials[t];
        }
      }
    };

    onMounted(() => {
      const tutorial_modal = document.getElementById("kt_tutorial_modal");
      if (tutorial_modal) {
        tutorial_modal.addEventListener("hidden.bs.modal", pauseVideos);
        tutorial_modal.addEventListener("show.bs.modal", setVideos);
      }
    });

    onBeforeUnmount(() => {
      const tutorial_modal = document.getElementById("kt_tutorial_modal");
      if (tutorial_modal) {
        tutorial_modal.removeEventListener("hidden.bs.modal", pauseVideos);
      }
    });

    return {
      // videos,
      pauseVideos,
      videoRefs,
      select_video,
      setVideos,
      tutorials,
      route,
      translate,
    };
  },
});
