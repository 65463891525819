import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

export const globals = defineStore("globalsInfo", () => {
  // state
  const asideMinimize = ref(
    window.localStorage.getItem("asideMinimizeStatus") === "true"
  );

  const changeAsideWidth = (status) => {
    asideMinimize.value = status;
    window.localStorage.setItem("asideMinimizeStatus", status);
  };

  return {
    asideMinimize,
    changeAsideWidth,
  };
});
