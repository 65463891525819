
import { defineComponent, computed ,ref} from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import UserInfo from "@/core/services/UserInfo";
import { translate } from "@/core/services/HelpersFun";
export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    const user = computed(() => {
      return UserInfo.getUser();
    });

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "fa";

    const countries = {
      fa: {
        flag: "/media/flags/iran.svg",
        name: "Persian",
        short_name: "fa",
        country: "iran",
      },
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English",
        short_name: "en",
        country: "united-states",
      },
      tr: {
        flag: "/media/flags/turkey.svg",
        name: "Turkey",
        short_name: "tr",
        country: "turkey",
      },
    };

    const signOutModal = ref(false)

    const signOut = () => {
      localStorage.removeItem('permissions')
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
      window.location.reload();
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLanguageLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      signOutModal,
      signOut,
      setLang,
      currentLanguage,
      currentLanguageLocale,
      countries,
      user,
      translate,
    };
  },
});
