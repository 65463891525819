
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Loader",
  props: {
    logo: String,
  },
  setup() {
    const store = useStore();

    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const loaderType = computed(() => {
      return store.getters.layoutConfig("loader.type");
    });

    const loaderLogo = computed(() => {
      return store.getters.layoutConfig("loader.logo");
    });

    return {
      loaderType,
      translate,
      loaderLogo,
    };
  },
});
